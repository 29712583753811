import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Element from './element';
import Button from '@material-ui/core/Button';

function Consultor() {
  let resetedEndereco = { tipo: 'RESIDENCIAL' };
  let resetedFone = { tipo: 'CELULAR' };
  let resetedEmail = { };
  let data = {
    pessoa: { tipo: 'PF', enderecos: resetedEndereco, telefones: resetedFone, emails: resetedEmail },
    status: 'PENDENTE',
    tipo: 'CONSULTOR',
    dadosComerciais: {
      statusPenn: 'ATIVO'
    }
  };

  let [evoluirDiEnergy, setEvoluirDiEnergy] = useState(false);
  let [openDialog, setOpenDialog] = useState(false);
  let [openDialogItem, setOpenDialogItem] = useState(false);
  let [view, setView] = useState({});
  let [status, setStatus] = useState({ dados: false, pontos: false, carta: false, entrevista: false, documentos: false });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [idConsultor, setIdConsultor] = useState(0);
  let [distribuidor, setDistribuidor] = useState(0);
  let split = window.location.pathname.substring(1).split('/');
  let [stage, setStage] = useState('list');
  let [tab, setTab] = useState('dados');
  let [crud, setCrud] = useState({ tabConsultor: 'consultores', data: data, changed: data, requireds: {} });
  let [crudItem, setCrudItem] = useState({ data: {}, changed: {}, requireds: {} });
  let user = utils.getUser();
  let [access, setAccess] = useState(window.access ? window.access : {});
  let [isCepValido, setIsCepValido] = useState(false);

  //BRA
  let [listaTipoDoctosEmpresa,setListaTipoDoctosEmpresa] = useState('list::MEI|CS|REQ_EMPRESARIO');
  let [listaTipoDoctosComprovantes,setListaTipoDoctosComprovantes] = useState('list::COMP_CNPJ|COMP_END_EMP|CONTA_BANCARIA|SINTEGRA|SIMPLES_NACIONAL|COMP_OUTRAS_MARCAS');
  let [listaTipoDoctoSocio,setListaTipoDoctoSocio] = useState('list::CNC|CE|RG|CPF|CNH|PROC');

  //MASCARAS
  let [cpfMask, setCpfMask] = useState("999.999.999-99"); // ***.***.999-99
  let [cnpjMask, setCnpjMask] = useState("99.999.999/9999-99"); // **.***.***/9999-99
  let [rgMask, setRgMask] = useState("9999999999"); // *****99999
  let [foneMask, setFoneMask] = useState("99999-9999"); // *****9999
  let [cepMask, setCepMask] = useState("99.999-999"); // 99.999-999
  
  if (split.length > 1) {
    stage = split[1];
  }

  if (split.length > 2) {
    tab = split[2];
  }

  if (tab === 'recomendar') {
    data.tipo = 'DI';
    data.status = 'RECOMENDACAO';
    data.pessoa.tipo = 'PJ';
    data.dadosComerciais.consultor = stage;
    data.dadosComerciais.statusPenn = 'RECOMENDACAO';
    data.dadosComerciais.kitHash = null;
    data.dadosComerciais.loteArs = null;
    data.dadosComerciais.participouMmn = false;
    data.dadosComerciais.outraMarca = null;
    data.dadosComerciais.possuiParentesco = null;
    data.dadosComerciais.grauParentesco = null;
    data.dadosComerciais.nomeParentesco = null;
  }

  view.refresh = function () {
    setIndex(index + 1);
  }

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  crud.data.tab = tab;


  const loadTiposDocumentos = function () {
    if (utils.getPaisUser() === 'PAR') {
      listaTipoDoctosEmpresa = 'list::OTROS_DOC_PAR|CCT_PAR|IS_FAT_PAR|RUC_PAR';
      listaTipoDoctosComprovantes = '';
      listaTipoDoctoSocio = 'list::CONTRATO_SPV_PAR|CONTRATO_DI_PAR|FICHA_ENTREV_PAR|FICHA_MONITOR_PAR|COMP_END_PAR|CEDULA_PAR';
    } else if (utils.getPaisUser() === 'ECU') {
      listaTipoDoctosEmpresa = '** PENDENTE **';
      listaTipoDoctosComprovantes = '** PENDENTE **';
      listaTipoDoctoSocio = '** PENDENTE **';
    } else if (utils.getPaisUser() === 'MEX') {
      listaTipoDoctosEmpresa = '** PENDENTE **';
      listaTipoDoctosComprovantes = '** PENDENTE **';
      listaTipoDoctoSocio = '** PENDENTE **';
    } else if (utils.getPaisUser() === 'BOL') {
      listaTipoDoctosEmpresa = '** PENDENTE **';
      listaTipoDoctosComprovantes = '** PENDENTE **';
      listaTipoDoctoSocio = '** PENDENTE **';
    } else if (utils.getPaisUser() === 'USA') {
      listaTipoDoctosEmpresa = '** PENDENTE **';
      listaTipoDoctosComprovantes = '** PENDENTE **';
      listaTipoDoctoSocio = '** PENDENTE **';
    }
    setListaTipoDoctosEmpresa(listaTipoDoctosEmpresa);
    setListaTipoDoctosComprovantes(listaTipoDoctosComprovantes);
    setListaTipoDoctoSocio(listaTipoDoctoSocio);
  }

  const loadMascaras = function() {
    let idPais = utils.getIdPaisUser();
    utils.execute(crud, 'FuncaoBasica?pais=' + idPais, null, function (res) {
      if (res.data.length > 0) {
        let listaFuncoesMascaras = res.data;

        for (let i=0; i<listaFuncoesMascaras.length; i++) {
          let funcao = listaFuncoesMascaras[i];
          if (funcao.formato) {
            if (funcao.constante.indexOf("CPF") > -1) {
              cpfMask = funcao.formato;
              setCpfMask(cpfMask);
            } else if (funcao.constante.indexOf("CNPJ") > -1) {
              cnpjMask = funcao.formato;
              setCnpjMask(cnpjMask);
            } else if (funcao.constante.indexOf("RG") > -1) {
              rgMask = funcao.formato;
              setRgMask(rgMask);
            } else if (funcao.constante.indexOf("TELEFONE") > -1) {
              foneMask = funcao.formato;
              setFoneMask(foneMask);
            } else if (funcao.constante.indexOf("CEP") > -1) {
              cepMask = funcao.formato;
              setCepMask(cepMask);
            }
          }
        }
        crud.refresh();
        setCrud(crud);
        setIndex(index + 1);
      }
    }, true);
  }

  const checkUpload = function () {
    if (!crud.data.count_empresa) {
      crud.data.count_empresa = 0;
    }
    if (!crud.data.count_socio) {
      crud.data.count_socio = 0;
    }
    if (!crud.data.count_comprovante) {
      crud.data.count_comprovante = 0;
    }
  }

  const consultarEvolucao = function () {
    utils.execute(crud, 'consultarEvolucaoConsultor?consultor=' + stage, null, function (res) {
      if (res.data.length > 0) {
        status = res.data[0];

        setStatus(status);
      }

      let fase = status.fase;

      delete status.fase;

      crud.permite_evoluir = true;
      crud.evolucao = {};

      for (const s in status) {
        const sv = status[s];

        if (!sv) {
          crud.permite_evoluir = false;
        }
        crud.evolucao[s] = sv;
      }
      refreshTotais();
      crud.refresh();
      setCrud(crud);
      setIndex(index + 1);
    }, true);

    utils.execute(crud, 'permitirEvoluirEnergy?single=true&id_consultor=' + stage, null, function (res) {
      crud.permiteEvoluirEnergy = res.data.evoluir_dienergy;
      
      setEvoluirDiEnergy(crud.permiteEvoluirEnergy);
      setIndex(index + 1);
    }, true);
  }

  const checkPermissaoAcessoDados = function(id_consultor, id_usuario, fnLoadConsultor) {
    utils.execute(crud, 'is_consultor_dentro_rede_usuario?id_consultor='+id_consultor+'&id_usuario'+id_usuario, null, function (res) {
      let alow = res.data && res.data[0] ? res.data[0].is_consultor_na_rede : false;
      if (alow) {
        fnLoadConsultor.call();
      } else {
        window.location.href = '/consultores/list';
      }
    });
  }

  const consultarDadosCadastrais = function () {
    utils.execute(crud, 'listarDadosCadastroConsultor?consultor=' + stage, null, function (res) {

      if (res.data[0] != undefined) {
        crud.data.pessoa.codigo = res.data[0].codigo;
        crud.data.dadosComerciais.outraMarca = res.data[0].outra_marca;
        crud.data.dadosComerciais.participouMmn = res.data[0].participou_mmn;
        crud.data.dadosComerciais.nomeParentesco = res.data[0].nome_parentesco;
        crud.data.dadosComerciais.possuiParentesco = res.data[0].possui_parentesco;
        crud.data.dadosComerciais.grauParentesco = res.data[0].grau_parentesco;
      }

      refreshTotais();
      crud.refresh();
      setCrud(crud);
      setIndex(index + 1);
    });
  }

  useEffect(() => {
    if (!ready) {
      setReady(true);

      loadTiposDocumentos();
      loadMascaras();
      if ('|list|create|'.indexOf(stage) < 0) {
        const id_consultor = parseInt(stage);
        setIdConsultor(id_consultor);

        checkPermissaoAcessoDados(id_consultor, user.id, () => {
          crud.data.aceite_consultor = true;

          if (tab === 'evoluir') {
            utils.execute(crud, 'ClienteDadosComerciais?single=true&target=cliente&lazy=false&resume=true&consultor=' + stage, null, function (res) {
              if (res.data && res.data.id) {
                const d = res.data;

                if (d.status.constante === 'APROVADO' || d.status.constante === 'EVOLUIR_PARA_DI' || d.status.constante === 'EVOLUIR_PARA_DIENERGY') {
                  crud.permite_evoluir = false;
                }

                crud.data.id_consultor = stage;
                crud.data.tipo = d.dadosComerciais.consultor.tipo.constante;
                crud.data.aceito = true;
                crud.distribuidor = d.id;
                setDistribuidor(d.id);
              }
              consultarEvolucao();
            }, true);
          } else if (tab === 'recomendar' || tab === 'documentos') {
            utils.execute(crud, 'ClienteDadosComerciais?single=true&target=cliente&lazy=false&resume=true&consultor=' + stage, null, function (res) {
              if (res.data && res.data.id) {
                let d = res.data;

                crud.data = d;
                crud.distribuidor = d.id;
                crud.data.aceito = true;
              } else {
                crud.recomendar = true;
              }
              crud.data.tab = tab;
              crud.data.id_consultor = id_consultor;
              crud.data.documentos = {};

              consultarDadosCadastrais();
              consultarEvolucao();
              checkUpload();
            }, true);
          } else {
            
            crud.data.id = id_consultor;
            crud.data.id_consultor = id_consultor;

            utils.execute(crud, 'Cliente/' + stage + '?resume=true', null, function (res) {
              let c = res.data;

              crud.data = c;
              crud.data.tab = tab;
              crud.data.id_consultor = id_consultor;


              if (c.usuario) {
                let dono = c.usuario;

                if (dono.id !== user.id) {
                  crud.data.editar = false;
                }
              }
              consultarEvolucao();
            }, true);
          }
        });
      } else if ('|create|'.indexOf(stage) >= 0) {
        utils.execute(crud, 'Cliente/0', null, function (res) {
          utils.merge(res.data, crud.data);

          crud.data.codigo = crud.data.pessoa.codigo;

          if (user.filialPrincipal) {
            crud.data.filial = user.filialPrincipal.id;
          }

          setIndex(index + 1);
        }, true);
      }
    }
  });

  crud.setarCEP = function (args) {
    console.log(args);
    setIsCepValido(args.data && args.data.cidade ? true : false);
    try {
      if (crud.elements.cidade.value !== args.data.cidade.descricao) {
        crud.data.pessoa.enderecos.cidade = args.data.cidade;
        crud.elements.cidade.setValue(crud.data.pessoa.enderecos.cidade);
      }
    } catch (e) {
      console.log(e);
    }
  };

  crud.trataValidacaoCNPJ = function (args) {
    console.log(args);
    try {
      if (args.data.jaExiste === true) {
        crud.data.pessoa.pessoaJuridica.cnpj = null;
        crud.exibeAlerta(args.data.mensagem);
      }
    } catch (e) {
      console.log(e);
    }
  };

  crud.trataValidacaoEmail = function (args) {
    console.log(args);
    try {
      if (args.data.jaExiste === true) {
        crud.data.pessoa.emails.email = null;
        crud.exibeAlerta(args.data.mensagem);
      }
    } catch (e) {
      console.log(e);
    }
  };

  crud.trataValidacaoTelefone = function (args) {
    console.log(args);
    try {
      if (args.data.jaExiste === true) {
        crud.data.pessoa.telefones.ddi = null;
        crud.data.pessoa.telefones.ddd = null;
        crud.data.pessoa.telefones.telefone = null;
        crud.exibeAlerta(args.data.mensagem);
      }
    } catch (e) {
      console.log(e);
    }
  };

  crud.exibeAlerta = function (msg) {
    let actions = [];
    actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} })
    if (window.dialog) {
      window.dialog.alert(msg, null, actions);
    }
  };

  crud.add = function (el, args) {
    window.location.href = '/consultores/create';
  };

  crud.openDialogEscolha = function (el, args) {
    if (crud.data.id > 0 && crud.data.status && crud.data.status.constante === "PENDENTE") {
      let actions = [];
      actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} })

      if (window.dialog) {
        window.dialog.alert(utils.lng(
          'consultor_pendente_orientar_aceite_email', 
          "Esse consultor consta como pendente, oriente-o a realizar o aceite através do e-mail informado no momento do cadastro"
        ), null, actions);
      }
    } else {
      setOpenDialog(true);
    }
  };

  crud.onUploadEmpresa = function () {
    checkUpload();
    crud.data.count_empresa = crud.data.count_empresa + 1;
    crud.onUpload();
  }

  crud.onUploadSocio = function () {
    checkUpload();
    crud.data.count_socio = crud.data.count_socio + 1;
    crud.onUpload();
  }

  crud.onUploadComprovante = function () {
    checkUpload();
    crud.data.count_comprovante = crud.data.count_comprovante + 1;
    crud.onUpload();
  }

  crud.onUpload = function () {
    crud.data.tipo_doc_comprovante = null;
    crud.data.tipo_doc_empresa = null;
    crud.data.tipo_doc_socio = null;

    crud.refresh();
  };

  crud.editarItem = function (el, args) {
    crudItem.data.id = args.data.id_vinculo;
    crudItem.changed.id = args.data.id_vinculo;
    crudItem.data.quantidade = args.data.quantidade;
    crudItem.changed.quantidade = args.data.quantidade;

    setOpenDialogItem(true);
  };

  const refreshTotais = function () {
    if (window.wProdutos) {
      window.wProdutos.search();
    }
    if (window.wRegras) {
      window.wRegras.search();
    }
  }

  crud.refreshTotais = refreshTotais;

  const handleCloseDialog = function () {
    setProdutosSelecionados({});
    msgResultadoVinculacao = '';
    window.location.reload();
  };

  const handleCloseDialogItem = function () {
    setOpenDialogItem(false);
  };

  const confirmarEdicaoItem = function () {
    if (crudItem.data.quantidade > 0) {
      const _ks_ = {};

      _ks_.crud = crudItem;
      _ks_.changed = crudItem.data;
      _ks_.element = {};

      _ks_.complete = function (res) {
        if (window.wProdutos) {
          window.wProdutos.search();
        }

        if (window.wRegras) {
          window.wRegras.search();
        }
        consultarEvolucao();
      };

      _ks_.cfg = {
        url: 'VendaVinculo/' + crudItem.data.id,
        method: 'PUT'
      };
      utils.call(_ks_);
      setOpenDialogItem(false);
    }
  };

  crud.setarTab = function (el, args) {
    let key = args.data.key;

    if (stage != 'create') {
      window.location.href = '/consultores/' + stage + '/' + key;
    }
  };

  crud.setarTabConsultor = function (el, args) {
    let key = args.data.key;

    crud.tabConsultor = key;

    crud.refresh();
  };

  crud.changeCpf = function (el, args) {
    let cpf = el.query;
    cpf = utils.onlyNumber(cpf, false);
    if (cpf && cpf.length >= 11) {
      crud.validaIsPessoaSocioOuConjuge(cpf);
    }
  };

  crud.validaIsPessoaSocioOuConjuge = function (cpfPessoa) {
    if (cpfPessoa) {
      utils.execute(crud, 'validaExistenciaSocioOuConjuge?documento=' + cpfPessoa, null, function (res) {
        if (res.data && (res.data.hasSocio === true || res.data.hasConjuge === true)) {
          let msgSocio = utils.lng(
            "cpf_ja_cadastrado_como_socio_impossivel_prosseguir", 
            "O CPF informado no registro de consultor já existe em nossa base como sócio! Não será possível prosseguir!"
          );
          let msgConjuge = utils.lng(
            "cpf_ja_cadastrado_como_conjuge_impossivel_prosseguir", 
            "O CPF informado no registro de consultor já existe em nossa base como cônjuge! Não será possível prosseguir!"
          );

          let msg = res.data.hasSocio ? msgSocio : msgConjuge;
          utils.prompt(
            {prompt: msg}, 
            () => {} , () => {}
          );
          crud.data.pessoa.pessoaFisica.cpf = '';
          crud.data.pessoa = { tipo: 'PF', enderecos: resetedEndereco, telefones: resetedFone, emails: resetedEmail };
          setIndex(index + 1);
        } else {
          crud.validaExistenciaPessoaPorCPF(cpfPessoa);
        }
      }, true);
    }
  };

  crud.validaExistenciaPessoaPorCPF = function (cpf) {
    utils.execute(crud, 'consultarCPF?cpf=' + cpf, null, function (res) {
      if (res.data.id) {
        utils.prompt(
          {prompt: utils.lng(
            "indentificamos_cadastro_com_cpf_deseja_continuar", 
            "Identificamos um cadastro já iniciado com o CPF informado, caso queira que os dados sejam pré-carregados clique no botão confirmar"
          )}, 
          function () {
            let person = res.data;
            if (person) {
              person = crud.carregaPessoaFormulario(person);
              person.novoCodMundial = true;
              crud.data.pessoa = person;
              crud.validaClientePessoa(crud.data.pessoa);
            }
            setIndex(index + 1);
          }, 
          function () {
            crud.data.pessoa.pessoaFisica.cpf = '';
            setIndex(index + 1);
          }
        );
      }
    }, true);
  }

  crud.carregaPessoaFormulario = function (newPessoa) {
    let pessoaRetorno = newPessoa;
    pessoaRetorno.enderecos = pessoaRetorno.enderecos && pessoaRetorno.enderecos[0] ? pessoaRetorno.enderecos[0] : resetedEndereco;
    pessoaRetorno.telefones = pessoaRetorno.telefones && pessoaRetorno.telefones[0] ? pessoaRetorno.telefones[0] : resetedFone;
    pessoaRetorno.emails = pessoaRetorno.emails && pessoaRetorno.emails[0] ? pessoaRetorno.emails[0] : resetedEmail;
    if (pessoaRetorno.enderecos) {
      setIsCepValido(
        pessoaRetorno.enderecos.cep && pessoaRetorno.enderecos.cep.length === 8 && 
        pessoaRetorno.enderecos.cidade && pessoaRetorno.enderecos.id
      );
    }
    return pessoaRetorno;
  }

  crud.validaClientePessoa = function (pessoaCarregada) {
    if (pessoaCarregada && pessoaCarregada.id) {
      utils.execute(crud, 'v1/consulta_cliente_por_pessoa?id_pessoa=' + pessoaCarregada.id, null, function (res) {
        if (res.data && res.data.pessoa && res.data.pessoa.id === pessoaCarregada.id) {
          utils.prompt(
            {prompt: utils.lng('ja_existe_cliente_pessoa', "Já existe um Cliente relacionado à esta pessoa! Não será possível prosseguir!")}, 
            () => {} , () => {}
          );
          crud.data.pessoa.pessoaFisica.cpf = '';
          crud.data.pessoa = { tipo: 'PF', enderecos: resetedEndereco, telefones: resetedFone, emails: resetedEmail };
          setIndex(index + 1);
        }
      }, true);
    }
  };

  crud.changeParticipaMmn = function () {
    if (crud.data.dadosComerciais.participaMmn !== false) {
      crud.data.dadosComerciais.chaveKitHash = null;
      crud.data.dadosComerciais.kitHash = null;
      crud.data.dadosComerciais.loteArs = null;
    }
  };

  crud.salvarCliente = function () {
    const _ks_validate = {};

    _ks_validate.crud = crud;
    _ks_validate.validate = true;
    _ks_validate.changed = crud.data;
    _ks_validate.element = {};

    _ks_validate.cfg = {
      url: 'Cliente/' + ( crud.data.id ? crud.data.id : '0' ) + '/validate',
      method: 'POST',
      alert: false
    };
    _ks_validate.complete = function (res) {
      if (res.data.validate) {
        crud.gravarPessoaAndCliente();
      }
    };
    utils.call(_ks_validate);
  };

  crud.gravarPessoaAndCliente = function () {
    if (crud.data && crud.data.pessoa) {
      if (!crud.data.pessoa.nomeReduz) {
        crud.data.pessoa.nomeReduz = crud.data.pessoa.nome;
      }
      const _ks_pessoa = {};

      _ks_pessoa.crud = crud;
      _ks_pessoa.validate = true;
      _ks_pessoa.changed = crud.data.pessoa;
      _ks_pessoa.element = {};

      _ks_pessoa.cfg = {
        url: 'Pessoa' + ( crud.data.pessoa.id ? '/'+crud.data.pessoa.id : '' ),
        method: crud.data.pessoa.id ? 'PUT' : 'POST',
        alert: false
      };
      _ks_pessoa.complete = function (res) {
        if (res && !res.error && res.data && res.data.id && crud.data) {
          crud.data.pessoa = crud.carregaPessoaFormulario(res.data);
          const _ks_cliente = {};
    
          _ks_cliente.crud = crud;
          _ks_cliente.validate = true;
          _ks_cliente.changed = crud.data;
          _ks_cliente.element = {};
    
          _ks_cliente.cfg = {
            url: 'Cliente'+ ( crud.data.id ? '/'+crud.data.id : '' ),
            method: crud.data.id ? 'PUT' : 'POST'
          };
          _ks_cliente.complete = crud.afterSaveCliente;
          utils.call(_ks_cliente);
        }
      };
      utils.call(_ks_pessoa);

    }
  };

  let [somaPontosProdutosSelecionados, setSomaPontosProdutosSelecionados] = useState(0);
  let [produtosSelecionados, setProdutosSelecionados] = useState({ });
  let msgResultadoVinculacao = '';
  
  const checkAll = function (allChecked, listaItens) {
    if (listaItens && listaItens != null && listaItens.length > 0) {
        view.loadingStart();
        let item = null;
        for (let i=0; i<listaItens.length; i++) {
            item = listaItens[i];
            item.selecionado = allChecked;
            manageSelecaoProdutos(item, false);
            if (i === (listaItens.length-1)) {
              view.loadingEnd();
            }
        }
    }
    refreshSomaPontos();
  };

  crud.selecionaProduto = function (el, args) {
    let item = args.data;
    if (item) {
      setTimeout(() => manageSelecaoProdutos(item), 700);
    }
  };

  crud.setQuantidadeSelecionada = function (el, item, xxx, yyy) {
    refreshSomaPontos();
  };

  const manageSelecaoProdutos = function (item, refreshPontos = true) {
    let check = item.selecionado;
    if (check === true) {
      addItem(item, refreshPontos);
    } else {
      removeItem(item, refreshPontos);
    }
  };

  const addItem = function (item, refreshPontos) {
    if (!produtosSelecionados[item.id_item]) {
      produtosSelecionados[item.id_item] = item;
      if (refreshPontos) {
        refreshSomaPontos();
      }
    }
  };

  const removeItem = function (item, refreshPontos) {
    if (produtosSelecionados[item.id_item]) {
      delete produtosSelecionados[item.id_item];
      if (refreshPontos) {
        refreshSomaPontos();
      }
    }
  };

  const refreshSomaPontos = function() {
    let pts = 0.0;
    for (const id_item in produtosSelecionados) {
      let item = produtosSelecionados[id_item];
      if (item && item.selecionado && item.qtde_selecionada > 0 && item.qtde_selecionada <= item.quantidade) {
        pts += getPontosSelecionados(item);
      }
    }
    setSomaPontosProdutosSelecionados(utils.number(pts, 4));
  };

  const getPontosSelecionados = function (item) {
    return ((item.qtde_selecionada / item.quantidade) * item.pontos_item);
  }

  const confirmaVinculacaoProdutos = function() {
    let hasItensVincular = produtosSelecionados && produtosSelecionados != null && Object.entries(produtosSelecionados)[0];
    if (hasItensVincular) {
      let first = Object.entries(produtosSelecionados)[0][1];
      if (first) {
        view.loadingStart();
        sendItemVinculo(first, 0);
      }
    } else {
      let actions = [];
      actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} })

      if (window.dialog) {
        window.dialog.alert(utils.lng('nenhum_item_selecionado', "Nenhum Ítem selecionado"), null, actions);
      } 
    }
  };

  //envia os itens para víncular um por um, para aproveitar o POST e observer
  const sendItemVinculo = function (itemVinculo, index) {

    if (itemVinculo && itemVinculo.qtde_selecionada > 0 && itemVinculo.qtde_selecionada <= itemVinculo.quantidade) {

      let request = getNewRequest();
      let newVendaVinculo = getVendaVinculoData(itemVinculo);

      request.body = newVendaVinculo;

      request.complete = function (res) {
        msgResultadoVinculacao += '<br><b>'+itemVinculo.codigo_produto+'</b> - '+itemVinculo.descricao+': '+res.message;
        
        let nextIndex = index+1;
        let nextItem = Object.entries(produtosSelecionados)[nextIndex];
        if (nextItem) {
          sendItemVinculo(nextItem[1], nextIndex);
        } else {
          finalizaVinculacao();
        }
      };

      utils.call(request);

    } else {
      finalizaVinculacao();
    }
  };

  const finalizaVinculacao = function () {
    view.loadingEnd();
    const actions = [];
    const finishFunction = () => {
      setProdutosSelecionados({});
      msgResultadoVinculacao = '';
      handleCloseDialog();
    };

    actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: finishFunction })

    if (window.dialog) {
        window.dialog.alert(utils.lng('resultado_vinculacao', "Resultado Vinculação")+"<br>"+msgResultadoVinculacao, null, actions);
    } else {
        finishFunction.call();
    }

  };

  const getNewRequest = function () {
    const req = {};
    req.crud = crud;
    req.cfg = {
      url: 'VendaVinculo',
      alert: false,
      method: 'POST'
    };
    return req;
  };

  const getVendaVinculoData = function (item) {
    let newVendaVinculo = {};
    newVendaVinculo["hubPedVendaItem"] = item.id_item;
    newVendaVinculo["consultor"] = crud.data.id;
    newVendaVinculo["quantidade"] = item.qtde_selecionada;
    newVendaVinculo["pontos"] = getPontosSelecionados(item);
    newVendaVinculo["usuario"] = utils.getUser().id;
    return newVendaVinculo;
  }

  const escolhaProdutos = {
    name: 'escolhaProdutos',
    clientId: 'escolhaProdutos',
    type: 'list',
    layout: 'table',
    search: true,
    controls: false,
    size: 500,
    add: false,
    // widgetVar: 'produtoSelecionado',
    // itemVar: 'produtoSelecionado',
    readOnly: false,
    rowEdit: false,
    checkAllFunction: checkAll,
    columns: {
      selecionado: {
        type: 'checkbox',
        field: 'selecionado', 
        required: false, 
        nullValues: false,
        readOnly: false,
        isRefreshParent: false,
        space: 1,
        click: {
          action: 'selecionaProduto',
          closeDialog: false
        }
      },
      avatar: { type: 'avatar' },
      codigo_produto: { label: utils.lng("codigo","Código") },
      numero: { label: utils.lng("pedido","Pedido") },
      data_pedido: { label: "Data", type: 'datetime' },
      descricao: { label: utils.lng("descricao",'Descrição') },
      quantidade: { label: utils.lng("quantidade",'Quantidade') },
      qtde_selecionada: {
        label: utils.lng("quantidade_selecionada",'Qtde. Selecionada'), 
        rowEdit: true, 
        type: 'spinner2',
        disableOnLimit: true, min: 1, max: "${@this.quantidade}",
        // disabled: "${!(@this.selecionado == true)}", 
        numeric: true, 
        sort: false,
        change: {
          action: 'setQuantidadeSelecionada'
        }
      }
    },
    list: {
      url: 'listarEscolhaPontosConsultor',
      params: {
        id_consultor: '${crud.data.id_consultor}'
      }
    }
  };

  const frmEditItem = {
    name: 'frmEditItem',
    elements: {
      quantidade: { type: 'number', label: utils.lng("quantidade",'Quantidade'), required: true }
    }
  }

  const consultores = {
    elements: {
      tabs: {
        type: 'list',
        layout: 'tab-menu',
        search: false,
        controls: false,
        itemVar: 'ti',
        rowStyleClass: '${ti.key === crud.tabConsultor ? "ui-tab-menu-sel" : ""}',
        header: false,
        columns: {
          label: { type: 'span' }
        },
        click: {
          action: 'setarTabConsultor'
        },
        data: [
          { label: utils.lng("consultores_upper",'CONSULTORES'), key: 'consultores' },
          { label: utils.lng("di_energy_upper",'DI ENERGY'), key: 'consultores_energy' },
          { label: utils.lng("di_nipponflex_upper",'DI NIPPONFLEX'), key: 'distribuidores' }
        ]
      },
      content: {
        styleClass: 'ui-lista-consultores',
        elements: {
          tb_a: {
            rendered: "${crud.tabConsultor  === 'consultores'}",
            header: false,
            layout: 'clear',
            add: true,
            print: false,
            type: 'list',
            list: {
              url: 'listarMeusConsultores',
              send: {
                query: '${@this.query}'
              }
            },
            click: {
              action: 'open'
            },
            columns: {
              card: {
                type: 'card',
                elements: {
                  avatar: { field: 'avatar', styleClass: 'noppading' },
                  detalhes: {
                    style: { fontSize: '10px' },
                    type: 'span', field: 'detail',
                    elements: {
                      nome: { field: 'nomes', value: '${@this.codigo + " - " + @this.nome}', style: { fontSize: '14px' } },
                      nome_pai: { field: 'nome_pai', value: utils.lng('nome_pai_label_e_nome','<b>PAI:</b> ${@this.nome_pai}') },
                      cidade: { field: 'cidade_uf', value: utils.lng('cidade_uf_label_e_cidade', "<b>CIDADE:</b> ${@this.cidade_uf}") },
                      tipo_cliente: { field: 'tipo_cliente', value: utils.lng('tipo_cliente_label_e_tipo', "<b>TIPO:</b> ${@this.tipo_cliente}")},
                      data_adm: { field: 'data_adm', value: utils.lng('data_adm_label_e_data', "<b>DATA DE ADMISSÃO:</b> ${@this.data_admissao}")}
                    }
                  },
                  remove: { type: 'button', rendered: false, icon: 'fa fa-trash', click: { url: 'Cliente/{id}', method: 'DELETE' } }
                }
              }
            }
          },
          tb_b: {
            layout: 'clear',
            rendered: "${crud.tabConsultor  === 'distribuidores'}",
            header: false,
            add: false,
            print: false,
            type: 'list',
            list: {
              url: 'listarMeusDistribuidores',
              send: {
                query: '${@this.query}'
              }
            },
            columns: {
              card: {
                type: 'card',
                elements: {
                  avatar: { field: 'avatar', styleClass: 'noppading' },
                  nome: { field: 'nome' },
                  status_descricao: { field: 'status_descricao', style: { textAlign: 'right' } },
                  remove: { type: 'button', rendered: false, icon: 'fa fa-trash', click: { url: 'Cliente/{id}', method: 'DELETE' } }
                }
              }
            }
          },
          tb_e: {
            rendered: "${crud.tabConsultor  === 'consultores_energy'}",
            header: false,
            layout: 'clear',
            add: false,
            type: 'list',
            list: {
              url: 'listarMeusConsultoresEnergy',
              send: {
                query: '${@this.query}'
              }
            },
            click: {
              action: 'open'
            },
            columns: {
              card: {
                type: 'card',
                elements: {
                  avatar: { field: 'avatar', styleClass: 'noppading' },
                  detalhes: {
                    style: { fontSize: '10px' },
                    type: 'span', field: 'detail',
                    elements: {
                      nome: { field: 'nomes', value: '${@this.codigo + " - " + @this.nome}', style: { fontSize: '14px' } },
                      nome_pai: { field: 'nome_pai', value: utils.lng('nome_pai_label_e_nome','<b>PAI:</b> ${@this.nome_pai}') },
                      cidade: { field: 'cidade_uf', value: utils.lng('cidade_uf_label_e_cidade', "<b>CIDADE:</b> ${@this.cidade_uf}") },
                      tipo_cliente: { field: 'tipo_cliente', value: utils.lng('tipo_cliente_label_e_tipo', "<b>TIPO:</b> ${@this.tipo_cliente}")},
                      data_adm: { field: 'data_adm', value: utils.lng('data_adm_label_e_data', "<b>DATA DE ADMISSÃO:</b> ${@this.data_admissao}")}
                    }
                  },
                  remove: { type: 'button', rendered: false, icon: 'fa fa-trash', click: { url: 'Cliente/{id}', method: 'DELETE' } }
                }
              }
            }
          }
        }
      }
    }
  };

  const cconsultor = {
    name: 'consultor',
    elements: {
      tabs: {
        type: 'list',
        layout: 'tab-menu',
        search: false,
        controls: false,
        itemVar: 'ti',
        rowStyleClass: '${ti.key === crud.data.tab ? "ui-tab-menu-sel" : ""}',
        header: false,
        columns: {
          label: { type: 'span' }
        },
        click: {
          action: 'setarTab'
        },
        data: [
          { label: utils.lng('dados_cadastrais','Dados cadastrais'), key: 'dados' },
          { label: utils.lng('pontos','Pontos'), key: 'pontos' },
          { label: utils.lng('recomendar','Recomendar'), key: 'recomendar' },
          { label: utils.lng('documentos','Documentos'), key: 'documentos' },
          { label: utils.lng('evoluir_para_di','Evoluir para D.I'), key: 'evoluir' }
        ]
      },
      content: {
        disabled: '${crud.data.editar === false}',
        elements: {
          regras: {
            type: 'list',
            search: false,
            header: false,
            widgetVar: 'wRegras',
            controls: false,
            rendered: "${crud.data.id > 0}",
            list: {
              url: 'listarRegraAdesao',
              send: {
                id_consultor: '${crud.data.id_consultor}'
              }
            },
            rowStyleClass: "ui-fase ui-fase-class-ajust ui-fase-${@this.passou_individual && @this.passou_requeridos ? 'ok' : 'err'}",
            columns: {
              fase: {
                type: 'include',
                src: '/templates/fase.html'
              }
            },
            space: 12
          },
          bt_ev_confirmar: {
            type: 'button',
            space: 6,
            styleClass: 'buttonEvoluir',
            disabled: "${!crud.evolucao.permite_alterar_consultor}",
            rendered: "${crud.permiteEvoluirEnergy !== undefined && crud.permiteEvoluirEnergy && @this.tipo.constante === 'CONSULTOR' && !crud.evolucao.carta}",
            label: utils.lng('evoluir_como_di_energy','Evoluir como DIEnergy'),
            click: {
              prompt: utils.lng('confirmar_evo_consultor_como_di_energy','Confirmar evolução do consultor como DIEnergy?'),
              url: 'Cliente/' + idConsultor,
              method: 'PUT',
              send: {
                tipo: 'CONSULTOR_ENERGY',
              },
              validate: false,
              redirect: true,
              reload: true
            },
          },
          no_permition:{
            rendered:"${crud.data.tab === 'documentos' && !crud.evolucao.permite_alterar_consultor && !user.administrativo}",
            elements: {
              empty: {
                style:{
                  textAlign:"center"
                },
                type: "card",
                elements: {
                  no_result:{
                    type: "span",
                    label: utils.lng('sem_permissao_visualizar_doctos_consultor','Você não possui permissão para visualizar esses documentos, apenas o 1º D.I Ascendente')
                  }
                }
              }
            }
          },
          tab_lista_documentos: {
            rendered: "${crud.data.tab === 'documentos' && crud.evolucao.documentos}",
            elements: {
              card_docs_empresa: {
                label: utils.lng('lista_documentos','Lista de documentos'),
                type: 'card',
                elements: {
                  lista_docs: {
                    type: 'list',
                    list: {
                      url: 'listarDocumentosCliente?id_cliente=' + crud.distribuidor,
                      send: false
                    },
                    header: false,
                    columns: {
                      tipo: {},
                      url: { type: 'link' }
                    }
                  }
                }
              }
            }
          },
          tab_documentos: {
            rendered: "${crud.evolucao.permite_alterar_consultor && crud.data.tab === 'documentos' && !crud.evolucao.documentos}",
            edit: "${crud.distribuidor > 0}",
            elements: {
              card_docs_empresa: {
                label: utils.lng('documentos_empresa','Documentos da empresa'),
                type: 'card',
                disabled: listaTipoDoctosEmpresa == null || listaTipoDoctosEmpresa === '',
                elements: {
                  tipo_doc_empresa: {
                    type: 'select',
                    label: utils.lng('selecione_tipo_documento','Selecione tipo do documento'),
                    list: {
                      url: 'TipoDocumento',
                      params: {
                        identificador: listaTipoDoctosEmpresa
                      }
                    }
                  },
                  docs_empresa: {
                    type: 'file',
                    upload: true,
                    required: true,
                    preview: false,
                    dialog: true,
                    disabled: '${!@this.tipo_doc_empresa}',
                    multiple: true,
                    filesLimit: 3,
                    field: 'documentos.empresa',
                    label: utils.lng('escolher_arquivo','Escolher arquivo'),
                    change: {
                      action: 'onUploadEmpresa'
                    },
                    mapping: {
                      data: 'caminho'
                    },
                    params: {
                      tipoDocumento: '${@this.tipo_doc_empresa}'
                    }
                  }
                }
              },
              card_docs_comprovante: {
                label: utils.lng('comprovantes','Comprovantes'),
                disabled: listaTipoDoctosComprovantes == null || listaTipoDoctosComprovantes === '',
                type: 'card',
                elements: {
                  tipo_doc_comprovante: {
                    type: 'select',
                    label: utils.lng('selecione_tipo_documento','Selecione tipo do documento'),
                    list: {
                      url: 'TipoDocumento',
                      params: {
                        identificador: listaTipoDoctosComprovantes
                      }
                    }
                  },
                  docs_comprovante: {
                    type: 'file',
                    upload: true,
                    required: true,
                    multiple: true,
                    preview: false,
                    filesLimit: 3,
                    dialog: true,
                    disabled: '${!@this.tipo_doc_comprovante}',
                    field: 'documentos.comprovantes',
                    label: utils.lng('escolher_arquivo','Escolher arquivo'),
                    change: {
                      action: 'onUploadComprovante'
                    },
                    mapping: {
                      data: 'caminho'
                    },
                    params: {
                      tipoDocumento: '${@this.tipo_doc_comprovante}'
                    }
                  }
                }
              },
              card_docs_socio: {
                label: utils.lng('documentos_socios','Documentos dos sócios'),
                type: 'card',
                disabled: listaTipoDoctoSocio == null || listaTipoDoctoSocio === '',
                elements: {
                  tipo_doc_socio: {
                    type: 'select',
                    label: utils.lng('selecione_tipo_documento','Selecione tipo do documento'),
                    list: {
                      url: 'TipoDocumentoPessoa',
                      params: {
                        constante: listaTipoDoctoSocio,
                        pais: utils.getIdPaisUser()
                      }
                    }
                  },
                  docs_socio: {
                    type: 'file',
                    upload: true,
                    multiple: true,
                    dialog: true,
                    preview: false,
                    filesLimit: 3,
                    label: utils.lng('escolher_arquivo','Escolher arquivo'),
                    disabled: '${!@this.tipo_doc_socio}',
                    field: 'documentos.socios',
                    change: {
                      action: 'onUploadSocio'
                    },
                    mapping: {
                      data: 'url'
                    },
                    params: {
                      tipo: '${@this.tipo_doc_socio}'
                    }
                  }
                }
              },
              botoes: {
                style: { marginTop: '15px' },
                elements: {
                  salvar: {
                    label: utils.lng('salvar_continuar','Salvar e continuar'),
                    type: 'button',
                    click: {
                      method: 'POST',
                      url: 'salvarDocumentosConsultor/{id}',
                      redirect: '/consultores/' + stage + '/evoluir'
                    }
                  }
                }
              }
            }
          },
          dados: {
            rendered: "${crud.data.tab === 'dados'}",
            disabled: "${crud.data.id > 0 && crud.data.status && crud.data.status.constante !== 'ATIVO'}",
            label: utils.lng('cadastrar_consultor','Cadastrar consultor'),
            type: 'legend',
            elements: {
              // boco_aceite: {
              //   styleClass: 'nomargin',
              //   type: 'card',
              //   elements: {
              //     campos chaveKitHash - kitHash - loteArs estavam aqui
              //   }
              // },
              boco_left: {
                space: 6,
                elements: {
                  card_geracao: {
                    type: 'card',
                    disabled: '${crud.data.id > 0}',
                    styleClass: 'nomargin-left',
                    label: utils.lng('geracao_ascendente','Geração Ascendente'),
                    elements: {
                      g_grupo: {
                        elements: {
                          geracao_label: {
                            field: 'dadosComerciais.clientePaiEmpresarial.descricao',
                            rendered: '${crud.data.id > 0}',
                            cols: 12, label: utils.lng('pai','Pai'),
                            type: 'text', disabled: true
                          },
                          geracao: {
                            cols: 12,
                            field: 'dadosComerciais.clientePaiEmpresarial',
                            rendered: '${!(crud.data.id > 0)}',
                            required: true,
                            label: utils.lng('selecionar','Selecionar'), type: 'entity', entity: 'Filial', size: 20, required: true, dialog: true,
                            columns: {
                              descricao: { label: utils.lng('nome','Nome') }
                            },
                            click: {
                              validate: false,
                              action: 'select'
                            },
                            selector: true,
                            list: {
                              url: 'listarPaiMonitor'
                            }
                          }
                        }
                      },
                      // tipo: {
                      //   field: 'tipo', label: 'Tipo', type: 'select', disabled: true, space: 6, data: [
                      //     { label: 'Consultor', value: 'CONSULTOR' }
                      //   ]
                      // },
                      data_admissao: { field: 'dadosComerciais.dataRegistroPenn', label: utils.lng('Data_de_admissao', 'Data de admissão'), type: 'date', required: true, space: 12 }
                    }
                  },
                  card_dados: {
                    type: 'card',
                    disabled: '${crud.data.id > 0 && !("|Adm Cadastro|SuperAdmin|".indexOf(user.perfil.descricao) > -1 )}',
                    styleClass: 'nomargin-left',
                    label: utils.lng('Dados_pessoais', 'Dados pessoais'),
                    elements: {
                      nome: { field: 'pessoa.nome', label: utils.lng('nome','Nome'), type: 'text', required: true , disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      cpf: { field: 'pessoa.pessoaFisica.cpf', mask: cpfMask, label: utils.lng('cpf_consult','CPF'), type: 'text', required: true, space: 6, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}", change: { action: "changeCpf" } },
                      rg: { field: 'pessoa.pessoaFisica.rg', label:utils.lng('rg_consult','RG'), mask: rgMask, type: 'text', numeric: true, required: true, space: 6, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      nascimento: { field: 'pessoa.pessoaFisica.dataNascimento', label: utils.lng('data_nascimento', 'Data de nascimento'), type: 'date', required: true, space: 6, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      estadoCivil: {
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}",
                        field: 'pessoa.pessoaFisica.estadoCivil',
                        label: utils.lng('estado_civil','Estado civil'), type: 'select', required: true, space: 6,
                        list: {
                          url: 'EstadoCivil',
                          send: false
                        }
                      },
                      cpfConjuge: {
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}",
                        field: 'pessoa.pessoaFisica.cpfConjuge', mask: cpfMask,
                        rendered: "${@this.pessoa.pessoaFisica.estadoCivil.constante === 'CASADO' || @this.pessoa.pessoaFisica.estadoCivil.constante === 'UNIAO_ESTAVEL'}",
                        required: "${@this.pessoa.pessoaFisica.estadoCivil.constante === 'CASADO' || @this.pessoa.pessoaFisica.estadoCivil.constante === 'UNIAO_ESTAVEL'}",
                        label: utils.lng('cpf_conjuge','CPF cônjuge'), type: 'text', required: true, space: 6,
                        list: {
                          url: 'EstadoCivil',
                          send: false
                        }
                      },
                      nomeConjuge: {
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}",
                        field: 'pessoa.pessoaFisica.nomeConjuge',
                        rendered: "${@this.pessoa.pessoaFisica.estadoCivil.constante === 'CASADO' || @this.pessoa.pessoaFisica.estadoCivil.constante === 'UNIAO_ESTAVEL'}",
                        required: "${@this.pessoa.pessoaFisica.estadoCivil.constante === 'CASADO' || @this.pessoa.pessoaFisica.estadoCivil.constante === 'UNIAO_ESTAVEL'}",
                        label: utils.lng('nome_conjuge','Nome cônjuge'), type: 'text', required: true, space: 6, help: "Aqui deve-se cadastrar o cônjuge do novo consultor"
                      },
                      dataNascimentoConjuge: {
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}",
                        field: 'pessoa.pessoaFisica.dataNascimentoConjuge',
                        rendered: "${@this.pessoa.pessoaFisica.estadoCivil.constante === 'CASADO' || @this.pessoa.pessoaFisica.estadoCivil.constante === 'UNIAO_ESTAVEL'}",
                        required: "${@this.pessoa.pessoaFisica.estadoCivil.constante === 'CASADO' || @this.pessoa.pessoaFisica.estadoCivil.constante === 'UNIAO_ESTAVEL'}",
                        label: utils.lng('data_nascimento_conjuge','Data de Nascimento do Cônjuge'), type: 'date', required: true, space: 6
                      },
                      ddi: { field: 'pessoa.telefones.ddi', label: utils.lng('ddi','DDI'), mask: '999', type: 'tel', required: false, space: 2, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      ddd: { field: 'pessoa.telefones.ddd', label: utils.lng('ddd','DDD'), mask: '99', type: 'tel', required: true, space: 2, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      telefone: { field: 'pessoa.telefones.telefone', label: utils.lng('telefone_whats)','Telefone (WhatsApp)'), mask: foneMask, type: 'tel', required: true, space: 4, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      email: { field: 'pessoa.emails.email', label: utils.lng('email','Email'), type: 'email', required: true, space: 6, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      filial: { disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.carta === true}", field: 'filial', label:utils.lng('filial_vinculo', 'Filial de Vínculo'), type: 'entity', entity: 'Filial', required: true, space: 6 },
                      participouMmn: { 
                        field: 'dadosComerciais.participouMmn', 
                        required: true, 
                        nullValues: true, 
                        label: utils.lng('participou_mmn_pergunta','Já participou de outras marcas do ramo de colchoaria?'), 
                        type: 'checkbox', 
                        space: 12, 
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.carta === true}",
                        change: {
                          action: 'changeParticipaMmn'
                        }
                      },
                      outraMarca: { field: 'dadosComerciais.outraMarca', label: utils.lng('qual_marca', 'Qual a marca?'), type: 'text', space: 12, rendered: '${@this.dadosComerciais.participouMmn == true}', required: true, disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.carta === true}" },
                      possuiParentesco: {
                        label: utils.lng('possui_parentesco', 'Possui parentesco com outro administrador qualificado?'),
                        type: 'checkbox', space: 12, field: 'dadosComerciais.possuiParentesco', nullValues: true, required: true,
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.carta === true}"
                      },
                      grauParentesco: {
                        field: 'dadosComerciais.grauParentesco', label: utils.lng('grau_de_parentesco', 'Grau de parentesco'), type: 'select', space: 6,
                        rendered: '${@this.dadosComerciais.possuiParentesco == true}', required: true,
                        disabled: "${!crud.evolucao.permite_alterar_consultor ||  crud.evolucao.carta === true}",
                        data: [
                          { label: utils.lng('pai','Pai'), value: 'Pai' },
                          { label: utils.lng('mae','Mãe'), value: 'Mãe' },
                          { label: utils.lng('tio_tia','Tio(a)'), value: 'Tio(a)' },
                          { label: utils.lng('avo_seletor','Avô(ó)'), value: 'Avô(ó)' },
                          { label: utils.lng('filho_seletor','Filho(a)'), value: 'Filho(a)' },
                          { label: utils.lng('irmao_seletor','Irmão(ã)'), value: 'Irmão(ã)' },
                          { label: utils.lng('sogro_seletor','Sogro(a)'), value: 'Sogro(a)' },
                          { label: utils.lng('primo_seletor','Primo(a)'), value: 'Primo(a)' },
                          { label: utils.lng('enteado_seletor','Enteado(a)'), value: 'Enteado(a)' },
                          { label: utils.lng('cunhado_seletor','Cunhado(a)'), value: 'Cunhado(a)' },
                          { label: utils.lng('sobrinho_seletor','Sobrinho(a)'), value: 'Sobrinho(a)' },
                        ],
                      },
                      nomeParentesco: {
                        field: 'dadosComerciais.nomeParentesco', label: utils.lng('qual_nome_parente', 'Qual o nome?'),
                        type: 'text', space: 6, rendered: '${@this.dadosComerciais.possuiParentesco == true}', required: true,
                        disabled: "${!crud.evolucao.permite_alterar_consultor || crud.evolucao.carta === true}"
                      },
                    }
                  }
                }
              },
              boco_right: {
                space: 6,
                styleClass: 'nomargin',
                type: 'card',
                elements: {
                  chaveKitHash: {
                    field: 'dadosComerciais.chaveKitHash',
                    // elRequired: "${user.codigo_pais === 'BRA' && !exists(@this,'dadosComerciais.chaveKitHash') && !exists(@this,'dadosComerciais.loteArs') && !equals(@this,'dadosComerciais.participouMmn', true)}",
                    required: false,
                    disabled: "${equals(@this,'dadosComerciais.participouMmn', true)}",
                    rendered: user.codigo_pais === 'BRA',
                    label: utils.lng('cod_ativacao_chave_kit_hash','Cód. de Ativação: ARA (todos), KBE (todos), Alcaline Power ou 2 Travesseiros') ,
                    edit: "${!(crud.data.id > 0)}",
                    type: 'text', 
                    maxLength: 5
                  },
                  ou: {
                    label: utils.lng('ou','OU'),
                    rendered: user.codigo_pais === 'BRA' && user.perfil.descricao === 'Distribuidor Independente (D.I)' || user.perfil.descricao === 'Diretoria' || user.perfil.descricao === 'Atendente Filial' || user.perfil.descricao === 'Adm Cadastro',
                    styleClass: "ui-bold ui-big ui-center",
                    type: 'label'
                  },
                  kitHash: {
                    field: 'dadosComerciais.kitHash',
                    label: utils.lng('codigo_ativacao','Código de ativação'),
                    required: false,
                    rendered: false,
                    edit: "${!(crud.data.id > 0)}",
                    type: 'select',
                    itemValue: 'id_hash',
                    itemLabel: 'codigo',
                    maxLength: 5,
                    list: {
                      url: 'listarKitHash',
                      send: {
                        id_consultor: '${crud.data.id}'
                      }
                    }
                  },
                  loteArs: {
                    field: 'dadosComerciais.loteArs',
                    label: utils.lng('lote_ars','Lote: ARS'),
                    disabled: "${(crud.evolucao && !crud.evolucao.permite_alterar_consultor) || equals(@this,'dadosComerciais.participouMmn', true)}",
                    rendered: user.codigo_pais === 'BRA' && user.perfil.descricao === 'Distribuidor Independente (D.I)' || user.perfil.descricao === 'Diretoria' || user.perfil.descricao === 'Atendente Filial' || user.perfil.descricao === 'Adm Cadastro',
                    // elRequired: "${user.codigo_pais === 'BRA' && !exists(@this,'dadosComerciais.chaveKitHash') && !exists(@this,'dadosComerciais.loteArs') && !equals(@this,'dadosComerciais.participouMmn', true)}",
                    required: false,
                    type: 'text', 
                    maxLength: 9
                  },
                  card_bancarios: {
                    type: 'card',
                    rendered: false,
                    disabled: '${crud.data.id > 0 && !("|Adm Cadastro|SuperAdmin|".indexOf(user.perfil.descricao) > -1 )}',
                    label: utils.lng('dados_bancarios','Dados bancários'),
                    styleClass: 'nomargin-right',
                    elements: {
                      banco: {
                        field: 'pessoa.dadosBancarios.banco',
                        label: utils.lng('banco','Banco'),
                        type: 'select',
                        itemLabel: 'descricao',
                        itemValue: 'id',
                        list: {
                          url: 'Banco',
                          send: false
                        }, required: true
                      },
                      agencia: { field: 'pessoa.dadosBancarios.agencia', label: utils.lng('agencia','Agência'), type: 'number', required: true, space: 4 },
                      digitoAgencia: { field: 'pessoa.dadosBancarios.dvAgencia', label: utils.lng('digito','Digito'), type: 'number', required: true, space: 2 },
                      conta: { field: 'pessoa.dadosBancarios.conta', label: utils.lng('conta','Conta'), type: 'number', required: true, space: 4 },
                      digitoConta: { field: 'pessoa.dadosBancarios.dvConta', label: utils.lng('digito','Digito'), type: 'number', required: true, space: 2 }
                    }
                  },
                  card_endereco: {
                    type: 'card',
                    label: utils.lng('endereco','Endereço'),
                    styleClass: 'nomargin-right',
                    disabled: '${crud.data.id > 0 && !("|Adm Cadastro|SuperAdmin|".indexOf(user.perfil.descricao) > -1 )}',
                    elements: {
                      cep: {
                        field: 'pessoa.enderecos.cep', min: 8,
                        disabled:"${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}",
                        blur: {
                          action: 'blurCep'
                        },
                        change: {
                          url: 'consultarCEP',
                          complete: 'setarCEP',
                          target: 'pessoa.enderecos',
                          send: {
                            cep: '${@this.pessoa.enderecos.cep}'
                          }
                        }, label: utils.lng('cep_consult','CEP') , type: 'text', mask: cepMask, required: true
                      },
                      logradouro: { 
                        field: 'pessoa.enderecos.logradouro', 
                        label: utils.lng('logradouro','Logradouro'), 
                        type: 'text', 
                        required: true, 
                        space: 12, 
                        disabled:"${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"
                      },
                      numero: { field: 'pessoa.enderecos.numero', label: utils.lng('numero','Número'), type: 'text', required: true, space: 4, disabled:"${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      complemento: { field: 'pessoa.enderecos.complemento', label: utils.lng('complemento','Complemento'), type: 'text', space: 8, disabled:"${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      bairro: { field: 'pessoa.enderecos.bairro', label: utils.lng('bairro','Bairro'), type: 'text', required: true, space: 6, disabled:"${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}"},
                      cidade: {
                        field: 'pessoa.enderecos.cidade', 
                        value: '${@this.pessoa.enderecos.cidade.descricao}',
                        label: utils.lng('cidade','Cidade'), 
                        entity: 'Cidade',
                        add:false,
                        list: { url: 'listar_cidades_cadastro_consultor'},
                        type: 'entity', 
                        required: true,
                        disabled: isCepValido,
                        space: 6
                      }
                    }
                  }
                }
              },
              aceite_consultor: {
                disabled: '${!crud.evolucao.permite_alterar_consultor || crud.evolucao.is_consultor_evoluindo}',
                label: utils.lng ('aceite_carta_in','Declaro serem verdadeiras e assumo total resposabilidade pelas informações aqui prestadas.'),
                required: true,
                type: 'checkbox'
              },
              botoes: {
                style: { marginTop: '15px' },
                elements: {
                  salvar: {
                    label: utils.lng('salvar_continuar','Salvar e continuar'),
                    type: 'button',
                    rendered: '${@this.id <= 0}',
                    click: {
                      action: 'salvarCliente'
                    }
                  },
                  atualizar: {
                    disabled:"${!crud.evolucao.permite_alterar_consultor}",
                    rendered: '${@this.id > 0}',
                    label: utils.lng('salvar_continuar','Salvar e continuar'),
                    type: 'button',
                    click: {
                      method: 'PUT',
                      url: 'Cliente/{id}',
                      complete: "afterSaveCliente",
                      params: {
                        status: crud.data.status
                      }
                    }
                  }
                }
              }
            }
          },
          pontos: {
            rendered: "${crud.data.tab === 'pontos'}",
            type: 'conteiner',
            elements: {
              bloco_pontos: {
                type: 'legend',
                label: utils.lng('cadastrar_pontos','Cadastrar pontos'),
                elements: {
                  produtos: {
                    label: utils.lng ('produtos_comprado_consultor','Produtos comprados pelo consultor'),
                    type: 'list',
                    disabled: '${crud.evolucao.is_consultor_evoluindo && !evoluir_di_energy}',
                    // edit: "${!crud.evolucao.carta || (crud.evolucao.carta && !crud.evolucao.pontos) || (crud.evolucao.id_entrevista === 0 && crud.evolucao.entrevista)}",
                    edit: "${!crud.evolucao.carta || (crud.evolucao.carta && !crud.evolucao.pontos)}",
                    widgetVar: 'wProdutos',
                    add: {
                      action: 'openDialogEscolha'
                    },
                    columns: {
                      avatar: { type: 'avatar' },
                      codigo_produto: { label:utils.lng ('codigo', "Código") },
                      numero: { label: utils.lng('pedido',"Pedido") },
                      descricao: { label: utils.lng('descricao','Descrição') },
                      quantidade: {
                        label: utils.lng('quantidade','Quantidade'), type: 'number',
                        style: { width: '100px' }
                      },
                      pontos: { aggregate: 'sum', field: 'pontos', format: 'number', decimalCount: 4, label: utils.lng('pontos', 'Pontos'), filter: false, sort: false },
                      editar: {
                        styleClass: 'ui-col-edit',
                        icon: 'fa fa-edit',
                        rendered: "${crud.evolucao.carta === false}",
                        disabled: "${@this.bloquear_remocao_ponto === true}",
                        click: {
                          action: 'editarItem'
                        }
                      },
                      remove: {
                        styleClass: 'ui-col-del',
                        icon: 'fa fa-trash',
                        rendered: "${crud.evolucao.carta === false}",
                        disabled: "${@this.bloquear_remocao_ponto === true}",
                        click: {
                          url: 'VendaVinculo/{id_vinculo}',
                          redirect: true,
                          method: 'DELETE',
                          prompt: utils.lng('deseja_remove_item', 'Deseja realmente remover o item?')
                        }
                      }
                    },
                    list: {
                      url: 'listarItensPontosConsultor',
                      send: {
                        id_consultor: '${crud.data.id}'
                      }
                    }
                  }
                }
              },
              botoes: {
                style: { marginTop: '15px' },
                elements: {
                  salvar: {
                    label: utils.lng ('continuar','Continuar'),
                    type: 'button',
                    disabled: "${!crud.evolucao.pontos || crud.evolucao.is_consultor_evoluindo }",
                    click: {
                      action: 'openTab',
                      tab: 'recomendar'
                    }
                  }
                }
              }
            }
          },
          recomendar: {
            rendered: "${crud.data.tab === 'recomendar'}",
            type: 'conteiner',
            elements: {
              bloco_carta: {
                type: 'legend',
                label: utils.lng ('carta_recomendacao_label_1','Carta de recomendação'),
                elements: {
                  card_carta: {
                    styleClass: 'nomargin',
                    disabled: "${!crud.evolucao.permite_alterar_consultor || crud.recomendar !== true || !crud.evolucao.pontos_atingidos}",
                    label: utils.lng('carta_recomendacao_label_2','Preencher carta de recomendação para o novo DI'),
                    type: 'card',
                    bottom: {
                      recomendar: {
                        background: '#008000',
                        type: 'button',
                        disabled: "${!crud.evolucao.pontos_atingidos}",
                        label:utils.lng('eu_recomendo','Eu recomendo'),
                        click: {
                          url: 'Cliente',
                          method: 'POST',
                          redirect: true
                        }
                      }
                    },
                    elements: {
                      carta_left: {
                        space: 6,
                        style: { padding: '20px' },
                        elements: {
                          recarea: { value: utils.lng('carta_recomendacao_label_3', 'Carta de recomendação'), type: 'span' }
                        }
                      },
                      carta_right: {
                        space: 6,
                        elements: {
                          nome: { field: 'pessoa.nome', type: 'text', label: utils.lng('razao_social','Razão Social'), required: true },
                          ddi: { field: 'pessoa.telefones.ddi', label: utils.lng('ddi','DDI'), mask: '999', type: 'tel', required: false, space: 2 },
                          ddd: { 
                            field: 'pessoa.telefones.ddd', 
                            label: utils.lng('ddd','DDD'), 
                            mask: '99', 
                            type: 'tel', 
                            required: true, 
                            space: 2,
                            change: {
                              url: 'isTelefoneJaCadastrado',
                              complete: 'trataValidacaoTelefone',
                              requestWhen: 'crud.data.pessoa.telefones.ddd && crud.data.pessoa.telefones.telefone && crud.data.pessoa.telefones.telefone.length > 7',
                              send: {
                                telefone: '${@this.pessoa.telefones.ddd+""+@this.pessoa.telefones.telefone}',
                                pessoa: '${@this.pessoa.id}',
                                consultor: '${crud.data.id_consultor}'
                              }
                            }
                          },
                          telefone: { 
                            field: 'pessoa.telefones.telefone', 
                            label: utils.lng('telefone_whats', 'Telefone (WhatsApp)'), 
                            mask: foneMask, 
                            type: 'tel', 
                            required: true, 
                            space: 4,
                            change: {
                              url: 'isTelefoneJaCadastrado',
                              complete: 'trataValidacaoTelefone',
                              requestWhen: 'crud.data.pessoa.telefones.ddd && crud.data.pessoa.telefones.telefone && crud.data.pessoa.telefones.telefone.length > 7',
                              send: {
                                telefone: '${@this.pessoa.telefones.ddd+""+@this.pessoa.telefones.telefone}',
                                pessoa: '${@this.pessoa.id}',
                                consultor: '${crud.data.id_consultor}'
                              }
                            }
                          },
                          email: { 
                            field: 'pessoa.emails.email', 
                            label: utils.lng('email','Email'), 
                            type: 'email', 
                            required: true, 
                            space: 6,
                            change: {
                              url: 'isEmailJaCadastrado',
                              complete: 'trataValidacaoEmail',
                              requestWhen: 'crud.data.pessoa.emails.email.indexOf("@") > -1',
                              send: {
                                email: '${@this.pessoa.emails.email}',
                                pessoa: '${@this.pessoa.id}',
                                consultor: '${crud.data.id_consultor}'
                              }
                            }
                          },
                          cnpj: { 
                            field: 'pessoa.pessoaJuridica.cnpj', 
                            type: 'text', mask: cnpjMask, 
                            label: utils.lng('cnpj','CNPJ'), 
                            required: true, 
                            space: 6,
                            change: {
                              url: 'isCnpjJaCadastrado',
                              complete: 'trataValidacaoCNPJ',
                              send: {
                                cnpj: '${@this.pessoa.pessoaJuridica.cnpj}',
                                pessoa: '${@this.pessoa.id}',
                                cidade: '${@this.pessoa.enderecos.cidade.id}'
                              }
                            }
                          },
                          inscricaoEstadual: { 
                            field: 'pessoa.pessoaJuridica.ie', 
                            type: 'text', 
                            label: utils.lng('incricao_estadual', 'Inscrição Estadual'), 
                            required: "${user.codigo_pais === 'BRA'}", 
                            rendered: "${user.codigo_pais === 'BRA'}",
                            space: 6 
                          },
                          cep: {
                            field: 'pessoa.enderecos.cep', min: 8,
                            change: {
                              url: 'consultarCEP',
                              target: 'pessoa.enderecos',
                              complete: 'setarCEP',
                              send: {
                                cep: '${@this.pessoa.enderecos.cep}'
                              }
                            }, label: utils.lng('cep_consult','CEP') , type: 'text', mask: cepMask, required: true
                          },
                          logradouro: { field: 'pessoa.enderecos.logradouro', type: 'text', label: utils.lng ('endereco', 'Endereço'), required: true },
                          numero: { field: 'pessoa.enderecos.numero', type: 'text', label: utils.lng('numero', 'Numero'), required: true, space: 4 },
                          complemento: { field: 'pessoa.enderecos.complemento', type: 'text', label: utils.lng('complemento','Complemento'), space: 8 },
                          bairro: { field: 'pessoa.enderecos.bairro', label: utils.lng('bairro','Bairro'), type: 'text', required: true, space: 6 },
                          cidade: {
                            field: 'pessoa.enderecos.cidade', 
                            value: '${@this.pessoa.enderecos.cidade.descricao}',
                            label: utils.lng('cidade','Cidade'), 
                            entity: 'Cidade',
                            add:false,
                            list: { url: 'listar_cidades_cadastro_consultor'},
                            type: 'entity', 
                            required: true,
                            disabled: isCepValido,
                            space: 6,
                            change: {
                              url: 'isCnpjJaCadastrado',
                              complete: 'trataValidacaoCNPJ',
                              requestWhen: 'typeof crud.data.pessoa.enderecos.cidade.id === "number"',
                              send: {
                                cnpj: '${@this.pessoa.pessoaJuridica.cnpj}',
                                pessoa: '${@this.pessoa.id}',
                                cidade: '${@this.pessoa.enderecos.cidade.id}'
                              }
                            }
                          },
                          avatar: { field: 'pessoa.avatar', upload: true, dialog: true, showLabel: false, type: 'upload', label: utils.lng('adicionar_foto_consultor', 'Adicionar foto do consultor') },
                          ver_entrevista: {
                            type: 'link',
                            value: utils.lng('ver_entrevista','Ver entrevista'),
                            rendered: "${crud.evolucao.id_entrevista > 0}",
                            href: "${'/ver_entrevista/' + crud.evolucao.id_entrevista}"
                          }
                        }
                      },
                      aceite_carta: { field: 'aceito', label:utils.lng('aceite_termos', 'Aceite de termos'), disabled: false, type: 'checkbox', required: true, style: { marginTop: '40px' } }
                    }
                  }
                }
              },
              bloco_entrevista: {
                type: 'legend',
                label: utils.lng('entrevistar_consultor','Entrevistar consultor'),
                // desabilita enquanto não tiver os pontos
                // id_entrevista: usado para mostrar o botão Ver Entrevista
                // permite_alterar_consultor: apenas o 1º DI Ascendente
                // enviar_entrevista: usado para quando um Consulto Energy evolui (não precisa de entrevista) e depois ele quer evoluir em Consultor Nipponflex (precisa entrevista)
                // se passar o período de realização da entrevista, ela será considerada como feita (entrevista=true)
                rendered:"${crud.evolucao.permite_alterar_consultor && crud.evolucao.carta && crud.evolucao.enviar_entrevista && crud.evolucao.pontos_atingidos === true && crud.evolucao.entrevista === false}",
                elements: {
                  card_entrevista: {
                    styleClass: 'nomargin ui-inline-child',
                    type: 'card',
                    elements: {
                      entrevistador: {
                        type: 'list',
                        required: true,
                        size: 1,
                        dialog: true,
                        search: false,
                        controls: false,
                        label: utils.lng('enviar_para_entrevistador','Enviar para entrevistador'),
                        actions: [{ action: 'close', label: utils.lng('cancelar','Cancelar') }, {
                          url: 'solicitarEntrevista',
                          method: 'POST',
                          label: utils.lng('enviar_para_entrevistador','Enviar para entrevistador'),
                          send: {
                            id_entrevistador: '${@this.id_cliente}',
                            id_consultor: '${crud.data.id_consultor}'
                          }
                        }],
                        columns: {
                          avatar: {},
                          nome: { label: utils.lng('empresa','Empresa') },
                          socio: { label: utils.lng('administrador_qualificado', 'Administrador Qualificado') },
                          graduacao: { label: utils.lng('graduacao','Graduação') },
                          select: {
                            icon: '${@this.atual ? "fa fa-check" : "fa fa-circle-o"}'
                          }
                        },
                        list: {
                          url: 'listarEntrevistadores?consultor={id_consultor}&size=1',
                          send: false
                        }
                      }
                    }
                  }
                }
              },
              botoes: {
                style: { marginTop: '15px' },
                elements: {
                  salvar: {
                    label: utils.lng('continuar','Continuar'),
                    type: 'button',
                    disabled: "${!crud.evolucao.permite_alterar_consultor || !crud.evolucao.pontos_atingidos || crud.evolucao.is_consultor_evoluindo}",
                    click: {
                      action: 'openTab',
                      tab: 'documentos'
                    }
                  }
                }
              }
            }
          },
          evoluir: {
            rendered: "${crud.data.tab === 'evoluir'}",
            type: 'conteiner',
            elements: {
              fases: {
                type: 'list',
                layout: 'card-row',
                header: false,
                search: false,
                rowStyle: { color: "${crud.evolucao[@this.key] ? 'green' : '#D20400' }" },
                columns: {
                  grupo: {
                    layout: 'grid',
                    elements: {
                      descricao: { field: 'descricao' },
                      icone: { field: 'icone', style: { textAlign: 'right' }, icon: "${crud.evolucao[@this.key] ? 'fa fa-check' : 'fa fa-times' }" }
                    }
                  }
                },
                data: [
                  { descricao: utils.lng('dados_cadastrais','Dados cadastrais preechidos'), key: 'dados' },
                  { descricao: utils.lng('pontos_atingidos','Pontos atingidos'), key: 'pontos_atingidos' },
                  { descricao: utils.lng('carta_recomendacao','Carta de recomendação'), key: 'carta' },
                  { descricao: utils.lng('formulario_entrevista','Formulário de entrevista'), key: 'entrevista' },
                  { descricao: utils.lng('documentos','Documentos'), key: 'documentos' }
                ]
              },
              botoes: {
                style: { marginTop: '15px' },
                disabled: '${!access.EVOLUIR_DI && !user.administrativo}',
                elements: {
                  evoluir_para_di_nippon: {
                    label: utils.lng ('evoluir_para_di_nippon','Evoluir para D.I. NipponFlex'),
                    type: 'button',
                    disabled: "${!crud.evolucao.permite_alterar_consultor || (crud.evolucao.dados === false || crud.evolucao.carta === false || crud.evolucao.entrevista === false || crud.evolucao.documentos === false || crud.evolucao.pontos === false) || crud.data.editar === false}",
                    rendered: "${crud.evolucao.evoluir_di_energy === true}",
                    click: {
                      prompt: utils.lng('consultor_nipponflex','Esse consultor evoluíra para D.I. NipponFlex'),
                      url: 'cliente/{id_consultor}/evoluirDINipponFlex',
                      method: 'POST',
                      redirect: true,
                      reload: true
                    }
                  },
                  // Ao completar a fase 1 um consultor pode virar DI energy
                  salvar: {
                    disabled: '${!access.EVOLUIR_DI && !user.administrativo}',
                    label: utils.lng('enviar_confirmacao_para_di','Enviar confirmação para cadastrar D.I'),
                    type: 'button',
                    disabled: "${(crud.evolucao.dados === false || crud.evolucao.carta === false || crud.evolucao.entrevista === false || crud.evolucao.documentos === false || crud.evolucao.pontos_atingidos === false) || crud.data.editar === false}",
                    rendered: "${crud.evolucao.evoluir_di_energy === false && !crud.evolucao.is_consultor_evoluindo}",
                    click: {
                      method: 'PUT',
                      url: 'Cliente/' + distribuidor,
                      redirect: '/consultores',
                      prompt: crud.data.tipo === "CONSULTOR_ENERGY" ? utils.lng('consultor_evoluira_dienergy','Esse consultor evoluírá para D.I. Energy') : utils.lng('consultor_evoluira_dinipponflex','Esse consultor evoluírá para D.I. NipponFlex'),
                      send: {
                        status: crud.data.tipo === 'CONSULTOR_ENERGY' ? 'EVOLUIR_PARA_DIENERGY' : 'EVOLUIR_PARA_DI',
                        tipo: 'DI',
                        dadosComerciais: {
                          statusPenn: 'RECOMENDACAO'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  crud.open = function (el, args) {
    const d = args.data;
    const t = {};

    t.label = d.nome;
    t.key = 'consultor_' + d.id;
    t.view = '/consultores/' + d.id;
    t.path = '/consultores/' + d.id;

    utils.open(t);
  };


  crud.doUploadDocs = function (res) {
    const _ks_ = {};
    const _dt_ = {};


    _dt_.pessoa = {};
    _dt_.pessoa.documentos = crud.data.pessoa.documentos;

    _ks_.crud = crud;
    _ks_.changed = _dt_;
    _ks_.element = {};

    _ks_.cfg = {
      url: 'Cliente/' + crud.data.id,
      method: 'PUT'
    };
    utils.call(_ks_);

  }

  crud.afterSaveCliente = function (res) {
    if (!res.error) {
      const d = res.data;
      console.log('>> ' + d);

      if (d && d.id) {
        window.location.href = '/consultores/' + d.id + '/pontos';
      }
    }
  }

  crud.openTab = function (el, args) {
    const cfg = el.click;

    window.location.href = '/consultores/' + stage + '/' + cfg.tab;
  };

  crud.blurCep = function (el, args) {
    if (!el.query || el.query === '' || el.query == null) {
      crud.setarCEP({});
    }
  };

  window.crud = crud;

  return (
    <View name="cliente-consultor" view={view} >
      <div className="ui-consultor">
        {stage !== 'list' &&
          <Element value={cconsultor} crud={crud} data={crud.data} view={view} ></Element>
        }
        {stage === 'list' &&
          <Element value={consultores} crud={crud} data={crud.data} view={view} ></Element>
        }

        <Dialog maxWidth="lg" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span style={{ float: 'left' }}>{utils.lng('add_produtos', 'Adicionar produtos')}</span>
              <span style={{ float: 'right' }}>{utils.lng('pontos', 'Pontos')+': '+somaPontosProdutosSelecionados}</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <Element value={escolhaProdutos} crud={crud} data={crud.data} view={view} readOnly={false}></Element>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {utils.lng('fechar', 'FECHAR').toUpperCase()}
            </Button>
            <Button autoFocus onClick={confirmaVinculacaoProdutos} color="primary" style={ { backgroundColor: "#0a4c96", color: "#fff" } }>
              {utils.lng('vincular_produtos', 'VINCULAR PRODUTOS').toUpperCase()}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth="lg" onClose={handleCloseDialogItem} aria-labelledby="customized-dialog-title" open={openDialogItem}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialogItem}>
            {utils.lng('editar_item', 'Editar item')}
          </DialogTitle>
          <DialogContent>
            <Element value={frmEditItem} crud={crudItem} data={crudItem.data} view={view} ></Element>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogItem} color="primary">
              {utils.lng('fechar', 'FECHAR')}
            </Button>
            <Button autoFocus onClick={confirmarEdicaoItem} color="primary">
              {utils.lng('confirmar', 'CONFIRMAR').toUpperCase()}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </View >
  );
}

export default Consultor;